import { FC } from 'react';
import { Oswald as GoogleFont } from 'next/font/google';
import Head from 'next/head';

export const oswald = GoogleFont({
    weight: ['400', '600', '700'],
    style: ['normal'],
    subsets: ['latin'],
    display: 'swap',
});

export const Oswald: FC<{}> = () => (
    <Head>
        <style
            key="fontfaces-oswald"
            className={oswald.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-oswald: Oswald, ${oswald.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
            }}
        />
    </Head>
);
